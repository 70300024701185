.base-color-bg {
  background-color: #FF6600;
}

/****** IMAGES ******/

.shield-medium {
  background: url("assets/img/professional-shield.png") no-repeat center;
  background-size: 78px 94px;
  display: block;
  width: 78px;
  height: 94px;
  margin-left: auto;
  margin-right: auto;
}

.shield-bg {
  background: url("assets/img/professional-home-bg.jpg") no-repeat center;
  background-size: cover;
}

/****** TABS ******/

.tab-nav.tabs {
  color: #ff6600;
}

.tabs-striped .tab-item.tab-item-active {
  border-color: #ff6600;
}

.tabs-striped .tab-item.tab-item-active, .tabs-striped .tab-item.active, .tabs-striped .tab-item.activated {
    color: #ff6600;
}

.tabs-striped .tab-item.tab-item-active, .tabs-striped .tab-item.active, .tabs-striped .tab-item.activated {
  border-color: #ff6600;
}

/****** CALENDAR ******/

medsquire-calendar > div.header {
  background: #ff6600;
}

medsquire-calendar > div.week > span.day.selected,
medsquire-calendar > div.week > span.day.today.selected,
medsquire-calendar > div.week > span.day.selected.has-event {
  background: #d65703;
}

medsquire-calendar > div.week > span.day.has-event {
  background: #ff6600;
}

medsquire-calendar > div.week.names > span {
  color: #2875c7;
}
